import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { DataGridPro as MuiDataGridPro } from '@mui/x-data-grid-pro';
import { loadingStatesLookup } from '@truescope-web/react/lib/components/loading/constants';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import TooltipWithLink from '@truescope-web/react/lib/components/widgets/TooltipWithLink';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError } from '../../../../../components/Api';
import { useConfigContext } from '../../../../../components/Config/ConfigProvider';
import { useApiLookup } from '../../../../../components/providers/ApiLookupProvider';
import MoreOptionsMenu from '../../../../../components/widgets/MoreOptionsMenu';
import { updateSubscription } from '../../../../manageSubscriptions/ManageSubscriptionsConstants';
import { useReportsActivityContext } from '../../ReportsActivityProvider';
import FullDataGridLoader from '../FullDataGridLoader';
import PartialDataGridLoader from '../PartialDataGridLoader';
import { ascendingFirstSortOptions, descendingFirstSortOptions, pageSizeOptions } from '../constants';
import activitiesTableReducer, {
	beginLoadingState,
	getInitialState,
	updateItemValue,
	updatePaginationModel,
	updateSortModel,
	updateState
} from '../reducer';
import { cellValueRenderFunctions } from '../valueFormatters';
import { getRecipientActivity } from './constants';

const RecipientActivityTable = ({ recipient, recipientId }) => {
	const [getClientApi] = useApiLookup();
	const [{ workspace }] = useConfigContext();
	const [{ dateFrom, dateTo }] = useReportsActivityContext();
	const { showSnackbar } = useSnackbar();
	const [{ sortModel, nextSortModel, paginationModel, nextPaginationModel, items, loadingState, totalCount }, dispatch] = useReducer(
		activitiesTableReducer,
		getInitialState({
			defaultSortModel: [{ field: 'reportTemplateName', sort: 'asc' }]
		})
	);

	const loadData = useCallback(async () => {
		try {
			dispatch(beginLoadingState());
			const { items: newItems, totalCount: newTotalCount } = await getRecipientActivity(
				getClientApi,
				recipient,
				workspace.workspace_id,
				dateFrom,
				dateTo,
				nextSortModel?.[0]?.sort === 'desc',
				nextSortModel?.[0]?.field,
				nextPaginationModel.pageSize * nextPaginationModel.page,
				nextPaginationModel.pageSize
			);
			dispatch(
				updateState({
					items: newItems,
					totalCount: newTotalCount,
					loadingState: loadingStatesLookup.idle,
					paginationModel: nextPaginationModel,
					sortModel: nextSortModel
				})
			);
		} catch (e) {
			dispatch(updateState({ loadingState: loadingStatesLookup.idle, errorMessage: extractError(e) }));
		}
	}, [getClientApi, workspace?.workspace_id, dateFrom, dateTo, nextSortModel, nextPaginationModel, recipient, dispatch]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	const handleToggleSubscribe = useCallback(
		async ({ reportTemplateId, reportTemplateName }, shouldSubscribe) => {
			try {
				dispatch(updateState({ loadingState: loadingStatesLookup.partial }));
				const { message } = await updateSubscription(getClientApi, recipientId, null, reportTemplateId, shouldSubscribe, 'report');
				if (!stringIsNullOrEmpty(message)) {
					throw new Error(message);
				}
				showSnackbar(
					`recipient was successfully ${shouldSubscribe ? 'subscribed to' : 'unsubscribed from'} ${reportTemplateName}`,
					snackbarVariants.success
				);
				dispatch(updateItemValue('reportTemplateId', reportTemplateId, { isSubscribed: shouldSubscribe }));
			} catch (e) {
				showSnackbar(
					`failed to ${shouldSubscribe ? 'subscribe user to' : 'unsubscribe user from'} ${reportTemplateName} - ${e.message}`,
					snackbarVariants.error
				);
				dispatch(updateState({ loadingState: loadingStatesLookup.idle }));
			}
		},
		[getClientApi, recipientId, dispatch]
	);

	const columns = useMemo(
		() => [
			{
				field: 'reportTemplateName',
				headerName: 'Report name',
				width: 251,
				renderCell: ({ value, row }) => (
					<TooltipWithLink
						arrow
						title="Click to manage"
						linkTo={`/w/${workspace.workspace_id}/reports/templates/${row.reportTemplateId}`}
					>
						<span>{value}</span>
					</TooltipWithLink>
				),
				disableColumnMenu: true,
				sortingOrder: ascendingFirstSortOptions
			},
			{
				field: 'deliveryCount',
				headerName: 'Delivered',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'openCount',
				headerName: 'Opens',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'opensPercentage',
				headerName: 'Open rate',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatPercentage,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'clickCount',
				headerName: 'Clicks',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'clicksPercentage',
				headerName: 'Click rate',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatPercentage,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'bounceCount',
				headerName: 'Bounces',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'bouncesPercentage',
				headerName: 'Bounce rate',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatPercentage,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: '__more_options__',
				headerName: '',
				width: 50,
				sortable: false,
				renderCell: ({ row }) => (
					<span>
						<MoreOptionsMenu
							size="xs"
							disabled={false}
							options={[
								row.isSubscribed
									? { label: 'Unsubscribe', onClick: () => handleToggleSubscribe(row, false) }
									: { label: 'Subscribe', onClick: () => handleToggleSubscribe(row, true) }
							]}
						/>
					</span>
				),
				disableColumnMenu: true
			}
		],
		[workspace?.workspace_id, handleToggleSubscribe]
	);

	const handleSortModelChange = useCallback(
		(newSortModel) => {
			if (loadingState !== loadingStatesLookup.idle) {
				return;
			}
			dispatch(updateSortModel(newSortModel));
		},
		[dispatch, loadingState]
	);

	const handlePaginationModelChange = useCallback(
		(newPaginationModel) => {
			if (loadingState !== loadingStatesLookup.idle) {
				return;
			}
			dispatch(updatePaginationModel(newPaginationModel));
		},
		[dispatch, loadingState]
	);

	return (
		<div className="recipient-activity-table nested-table">
			{loadingState === loadingStatesLookup.full ? (
				<FullDataGridLoader />
			) : (
				<MuiDataGridPro
					className="activity-data-grid"
					rows={items}
					columns={columns}
					loading={loadingState === loadingStatesLookup.partial}
					getRowId={(row) => row.reportTemplateId}
					disableRowSelectionOnClick
					disableColumnFilter
					onSortModelChange={handleSortModelChange}
					sortModel={sortModel}
					slots={{
						loadingOverlay: PartialDataGridLoader
					}}
					rowCount={totalCount}
					pagination
					pageSizeOptions={pageSizeOptions}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={handlePaginationModelChange}
					disableColumnResize
					initialState={{
						pinnedColumns: { left: ['reportTemplateName'] }
					}}
				/>
			)}
		</div>
	);
};

export default RecipientActivityTable;

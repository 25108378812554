import { extractError } from '../../components/Api';

export const getManageSubscriptionsInfo = (getClientApi, guid) => {
	return getClientApi().then((api) =>
		api
			.get(`subscriptions/v1/${guid}`)
			.then(({ data }) => data)
			.catch((e) => {
				throw extractError(e);
			})
	);
};

export const updateSubscription = (
	getClientApi,
	recipient_id,
	subscription_reference,
	report_template_id,
	shouldSubscribe,
	type = 'report'
) => {
	return getClientApi().then((api) =>
		api
			.patch(`subscriptions/v1/${recipient_id}`, {
				subscription_reference,
				report_template_id,
				action: shouldSubscribe ? 'subscribe' : 'unsubscribe',
				type
			})
			.then(({ data }) => data)
			.catch((e) => {
				throw extractError(e);
			})
	);
};

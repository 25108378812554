import React, { Suspense, lazy, useMemo } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import ReportsActivityWrapper from '../views/reports/activity/ReportsActivity';
import { useConfigContext } from './Config/ConfigProvider';
import { entityTypeLookup } from './drawer/DrawerConstants';
import useActivityCapture from './hooks/analytics/useActivityCapture';
import useIntercom from './hooks/useIntercom';

const LoadConfig = lazy(() => lazyRetry(() => import('./Config/LoadConfig'), 'LoadConfig'));
const SignIn = lazy(() => lazyRetry(() => import('../views/authentication/SignIn'), 'SignIn'));
const SignOut = lazy(() => lazyRetry(() => import('../views/authentication/SignOut'), 'SignOut'));
const Dashboard = lazy(() => lazyRetry(() => import('../views/dashboard/Dashboard'), 'Dashboard'));
const Trends = lazy(() => lazyRetry(() => import('../views/discovery/trends/Trends'), 'Trends'));
const TrendsSearch = lazy(() => lazyRetry(() => import('../views/discovery/trends/TrendsSearch'), 'TrendsSearch'));
const ErrorDetails = lazy(() => lazyRetry(() => import('../views/error/ErrorDetails'), 'ErrorDetails'));
const Inbox = lazy(() => lazyRetry(() => import('../views/inbox/Inbox'), 'Inbox'));
const Hello = lazy(() => lazyRetry(() => import('../views/join/Hello'), 'Hello'));
const Join = lazy(() => lazyRetry(() => import('../views/join/Join'), 'Join'));
const ManageSubscriptionsPathParams = lazy(() =>
	lazyRetry(() => import('../views/manageSubscriptions/ManageSubscriptionsPathParams'), 'ManageSubscriptionsPathParams')
);
const ManageSubscriptionsQueryParams = lazy(() =>
	lazyRetry(() => import('../views/manageSubscriptions/ManageSubscriptionsQueryParams'), 'ManageSubscriptionsQueryParams')
);
const ReportTemplate = lazy(() => lazyRetry(() => import('../views/reports/template/ReportTemplate'), 'ReportTemplate'));
const MentionsAlert = lazy(() =>
	lazyRetry(() => import('../views/workspaceSettings/Alerts/MentionsAlerts/MentionsAlert'), 'MentionsAlert')
);
const MentionsAlerts = lazy(() =>
	lazyRetry(() => import('../views/workspaceSettings/Alerts/MentionsAlerts/MentionsAlerts'), 'MentionsAlerts')
);
const SpikeAlert = lazy(() => lazyRetry(() => import('../views/workspaceSettings/Alerts/SpikeAlerts/SpikeAlert'), 'SpikeAlert'));
const SpikeAlerts = lazy(() => lazyRetry(() => import('../views/workspaceSettings/Alerts/SpikeAlerts/SpikeAlerts'), 'SpikeAlerts'));
const Queries = lazy(() => lazyRetry(() => import('../views/queries/Queries'), 'Queries'));
const Query = lazy(() => lazyRetry(() => import('../views/queries/query/Query'), 'Query'));
const Theme = lazy(() => lazyRetry(() => import('../views/workspaceSettings/Themes/Theme'), 'Theme'));
const Themes = lazy(() => lazyRetry(() => import('../views/workspaceSettings/Themes/Themes'), 'Themes'));
const ContentSettings = lazy(() => lazyRetry(() => import('../views/workspaceSettings/Content/ContentSettings'), 'ContentSettings'));
const AuthenticatedRoute = lazy(() => lazyRetry(() => import('./AuthenticatedRoute'), 'AuthenticatedRoute'));
const UnauthenticatedRoute = lazy(() => lazyRetry(() => import('./UnauthenticatedRoute'), 'UnauthenticatedRoute'));
const UnauthenticatedView = lazy(() => lazyRetry(() => import('./UnauthenticatedView'), 'UnauthenticatedView'));
const InboxSettings = lazy(() => lazyRetry(() => import('../views/settings/inboxSettings/InboxSettings'), 'InboxSettings'));
const Profile = lazy(() => lazyRetry(() => import('../views/settings/profile/Profile'), 'Profile'));

const Assistant = lazy(() => lazyRetry(() => import('./../views/explore/assistant/Assistant'), 'Assistant'));

const lazyRetry = (importedComponent, importedComponentName) =>
	new Promise(async (resolve, reject) => {
		const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`lazy-retry-${importedComponentName}-refreshed`) || false);

		try {
			const component = await importedComponent();
			window.sessionStorage.setItem(`lazy-retry-${importedComponentName}-refreshed`, false);

			resolve(component);
		} catch (e) {
			if (!hasRefreshed) {
				window.sessionStorage.setItem(`lazy-retry-${importedComponentName}-refreshed`, true);
				return window.location.reload();
			}

			reject(e);
		}
	});

/**
 * gets the default routes based on the workspace state
 * @param {*} workspace current workspace
 * @returns route related settings
 */
const getDefaultRoutes = (workspace) => {
	if (isNullOrUndefined(workspace)) {
		return {
			dashboardReroute: '',
			reportsReroute: ''
		};
	}

	const dashboards = [
		...(workspace.userSettings?.dashboardPins || []).filter(
			(pin) => pin.entity_type === entityTypeLookup.dashboard && workspace.dashboardsLookup[pin.entity_id]
		),
		...(workspace.dashboards || []).filter((dashboard) => !isNullOrUndefined(dashboard.dashboard_id))
	];

	const reports = [
		...(workspace.userSettings?.reportTemplatePins || []).filter(
			(pin) => pin.entity_type === entityTypeLookup.report && workspace.reportTemplatesLookup[pin.entity_id]
		),
		...(workspace.reportTemplates || []).filter((reportTemplate) => !isNullOrUndefined(reportTemplate.report_template_id))
	];

	return {
		dashboardReroute: !arrayIsNullOrEmpty(dashboards) ? `${dashboards[0].dashboard_id || dashboards[0].entity_id}` : 'create',
		reportsReroute: !arrayIsNullOrEmpty(reports) ? `${reports[0].report_template_id || reports[0].entity_id}` : 'create'
	};
};

const LazyLoader = ({ component }) => (
	<Suspense
		fallback={
			<div className="lazy-loader">
				<CircularProgress />
			</div>
		}
	>
		{component}
	</Suspense>
);

const AppRoutes = () => {
	const [{ workspace }] = useConfigContext();
	const { pathname } = useMatch(`/w/:workspaceId/*`) || {};
	useActivityCapture();
	useIntercom();

	const { dashboardReroute, reportsReroute } = useMemo(
		() => getDefaultRoutes(workspace),
		[
			workspace?.dashboards,
			workspace?.permissions,
			workspace?.userSettings,
			workspace?.userSettings?.reportTemplatePins,
			workspace?.userSettings?.dashboardPins,
			workspace?.reportTemplates,
			workspace?.dashboardsLookup,
			workspace?.reportTemplatesLookup
		]
	);

	return (
		<Routes>
			<Route path="/w/:workspaceId" element={<LazyLoader component={<AuthenticatedRoute />} />}>
				<Route path="inbox">
					<Route index element={<LazyLoader component={<Inbox />} />} />
					<Route path="queries/:queryId" element={<LazyLoader component={<Inbox />} />} />
					<Route path="items/:itemId" element={<LazyLoader component={<Inbox />} />} />
					<Route path="junk" element={<LazyLoader component={<Inbox />} />} />
				</Route>
				<Route index element={<LazyLoader component={<Navigate to="inbox" replace />} />} />
				<Route path="explore">
					<Route index element={<LazyLoader component={<Navigate to="queries" replace />} />} />
					<Route path="trends">
						<Route index element={<LazyLoader component={<Trends />} />} />
						<Route path="search" element={<LazyLoader component={<TrendsSearch />} />} />
					</Route>
					<Route path="queries">
						<Route index element={<LazyLoader component={<Queries />} />} />
						<Route path="create" element={<LazyLoader component={<Query create />} />} />
						<Route path=":queryId" element={<LazyLoader component={<Query />} />} />
					</Route>
					<Route path="assistant" element={<LazyLoader component={<Assistant />} />} />
				</Route>
				<Route path="dashboards">
					<Route index element={<LazyLoader component={<Navigate to={dashboardReroute} replace />} />} />
					<Route path=":dashboardId" element={<LazyLoader component={<Dashboard />} />} />
					<Route path="create" element={<LazyLoader component={<Dashboard create />} />} />
				</Route>
				<Route path="reports">
					<Route index element={<Navigate to="templates" replace />} />
					<Route path="templates">
						<Route index element={<LazyLoader component={<Navigate to={reportsReroute} replace />} />} />
						<Route path=":reportTemplateId" element={<LazyLoader component={<ReportTemplate />} />} />
						<Route path="create" element={<LazyLoader component={<ReportTemplate create />} />} />
					</Route>
					<Route path="activity" element={<LazyLoader component={<ReportsActivityWrapper />} />} />
					<Route path="alerts">
						<Route index element={<LazyLoader component={<Navigate to="mentions/" replace />} />} />
						<Route path="mentions">
							<Route index element={<LazyLoader component={<MentionsAlerts />} />} />
							<Route path="create" element={<LazyLoader component={<MentionsAlert create />} />} />
							<Route path=":mentionsAlertId" element={<LazyLoader component={<MentionsAlert />} />} />
						</Route>
						<Route path="spikes">
							<Route index element={<LazyLoader component={<SpikeAlerts />} />} />
							<Route path="create" element={<LazyLoader component={<SpikeAlert create />} />} />
							<Route path=":spikeAlertId" element={<LazyLoader component={<SpikeAlert />} />} />
						</Route>
					</Route>
				</Route>
				<Route path="settings">
					<Route path="inbox" element={<LazyLoader component={<InboxSettings />} />} />
					<Route path="profile" element={<LazyLoader component={<Profile />} />} />
				</Route>
				<Route path="workspace">
					<Route path="themes">
						<Route index element={<LazyLoader component={<Themes />} />} />
						<Route path="create" element={<LazyLoader component={<Theme create />} />} />
						<Route path=":themeId" element={<LazyLoader component={<Theme />} />} />
					</Route>
					<Route path="content" element={<LazyLoader component={<ContentSettings />} />} />
				</Route>
				<Route path="content">
					<Route index element={<LazyLoader component={<Navigate to={pathname?.replace('content', 'queries')} replace />} />} />
					<Route path="queries">
						<Route index element={<LazyLoader component={<Navigate to={pathname?.replace('/content', '')} replace />} />} />
						<Route
							path=":queryId"
							element={<LazyLoader component={<Navigate to={pathname?.replace('/content', '')} replace />} />}
						/>
					</Route>
					<Route path="alerts">
						<Route
							index
							element={<LazyLoader component={<Navigate to={pathname?.replace('content', 'reports')} replace />} />}
						/>
						<Route path="mentions">
							<Route
								index
								element={<LazyLoader component={<Navigate to={pathname?.replace('content', 'reports')} replace />} />}
							/>
							<Route
								path="create"
								element={<LazyLoader component={<Navigate to={pathname?.replace('content', 'reports')} replace />} />}
							/>
							<Route
								path=":mentionsAlertId"
								element={
									<LazyLoader
										component={<Navigate to={pathname?.replace('content', 'reports')} replace match="content/*" />}
									/>
								}
							/>
						</Route>
						<Route path="spikes">
							<Route
								index
								element={<LazyLoader component={<Navigate to={pathname?.replace('content', 'reports')} replace />} />}
							/>
							<Route
								path="create"
								element={<LazyLoader component={<Navigate to={pathname?.replace('content', 'reports')} replace />} />}
							/>
							<Route
								path=":spikeAlertId"
								element={<LazyLoader component={<Navigate to={pathname?.replace('content', 'reports')} replace />} />}
							/>
						</Route>
					</Route>
					<Route
						path="options"
						element={
							<LazyLoader component={<Navigate to={pathname?.replace('/content/options', '/content-options')} replace />} />
						}
					/>
				</Route>
			</Route>
			<Route index element={<LazyLoader component={<LoadConfig />} />} />
			<Route path="/inbox" element={<LazyLoader component={<LoadConfig />} />} />
			<Route path="/sign-in" element={<LazyLoader component={<UnauthenticatedView children={<SignIn />} />} />} />
			<Route path="/sign-out" element={<LazyLoader component={<UnauthenticatedView children={<SignOut />} />} />} />
			<Route path="/signed-out" element={<LazyLoader component={<UnauthenticatedView children={<SignOut automatic />} />} />} />
			<Route path="/error" element={<LazyLoader component={<UnauthenticatedRoute />} />}>
				<Route index element={<LazyLoader component={<ErrorDetails />} />} />
				<Route path=":code" element={<LazyLoader component={<ErrorDetails />} />} />
			</Route>

			<Route path="/join/:guid" element={<LazyLoader component={<UnauthenticatedView children={<Join />} />} />} />

			<Route path="/manage" element={<LazyLoader component={<UnauthenticatedRoute />} />}>
				<Route index element={<LazyLoader component={<ManageSubscriptionsQueryParams />} />} />
				<Route path=":guid" element={<LazyLoader component={<ManageSubscriptionsPathParams />} />} />
				<Route path=":guid/:sid" element={<LazyLoader component={<ManageSubscriptionsPathParams />} />} />
				<Route path=":guid/:sid/:action" element={<LazyLoader component={<ManageSubscriptionsPathParams />} />} />
			</Route>

			<Route path="/hello" element={<LazyLoader component={<UnauthenticatedView children={<Hello />} />} />} />

			<Route path="*" element={<LazyLoader component={<UnauthenticatedView children={<ErrorDetails code="404" />} />} />} />
		</Routes>
	);
};

export default AppRoutes;

import { v4 } from 'uuid';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

const combineChunks = (chunks) => {
	chunks.sort((a, b) => a.sequence - b.sequence);
	const combinedData = chunks.map((chunk) => chunk.data).join('');
	return combinedData;
};

export const parseJsonOrString = (data) => {
	try {
		return JSON.parse(data);
	} catch (e) {
		return data;
	}
};

/**
 *
 * @param {object} data
 * @param {React.Ref} receivedChunksByGroupRef
 * @returns {object}
 */
export const handleChunkWithGrouping = (data, receivedChunksByGroupRef) => {
	if (data.chunkGroupId) {
		if (!receivedChunksByGroupRef.current[data.chunkGroupId]) {
			receivedChunksByGroupRef.current[data.chunkGroupId] = [];
		}

		receivedChunksByGroupRef.current[data.chunkGroupId].push(data);

		const totalChunksForGroup = data.totalChunks;
		const receivedChunksForGroup = receivedChunksByGroupRef.current[data.chunkGroupId];

		if (receivedChunksForGroup.length !== totalChunksForGroup) {
			return null;
		}

		const reassembledContent = combineChunks(receivedChunksForGroup);
		delete receivedChunksByGroupRef.current[data.chunkGroupId];

		return parseJsonOrString(reassembledContent);
	} else {
		return parseJsonOrString(data);
	}
};

/**
 * Allows you to send a websocket request asynchronously and await the response.
 * @param {boolean} isReady
 * @param {Websocket} socket
 * @param {JSON} data
 * @param {function} sendFunc
 * @returns {void}
 * Note: move this to utils v2 when we use that in client.
 */
export const sendWebSocketRequestPromise = (isReady = false, socket, data, sendFunc, websocketMessageId = undefined) => {
	const socketConnection = socket.current || socket;
	if (isNullOrUndefined(socketConnection.send)) {
		return Promise.reject('No websocket send function');
	}
	if (!isReady) {
		return Promise.reject('No websocket connection');
	}

	return new Promise((resolve, reject) => {
		let messageId = websocketMessageId || v4();
		sendFunc({ messageId, ...data }, true);

		socketConnection.addEventListener('onmessage', (event) => {
			if (event.detail.messageId !== messageId) {
				return;
			}
			resolve(event.detail);
		});

		socket.onerror = function (error) {
			if (error.messageId !== messageId) {
				return;
			}
			reject(new Error('WebSocket error: ' + error.message));
			socket.onerror = null;
		};

		socketConnection.removeEventListener('onmessage', resolve);
	});
};

export const isConnected = (webSocketRef) => webSocketRef.current?.readyState === WebSocket.OPEN;
export const isConnecting = (webSocketRef) => webSocketRef.current?.readyState === WebSocket.CONNECTING;
